import React from "react";
import moment from "moment";
import TableWrapper from "../common/TableWrapper";
import { Link } from "react-router-dom";
import Button from "../common/Button";
import styles from "./TableStyles.module.scss";
import downloadIcon from "../../static/images/download-icon.svg";
import { downloadInvoices } from "../utils";

const columns = [
  {
    header: "Service",
    render: (e) => (
      <>
        <b>{e.id}</b>
        <div>{moment(e.started_at).format("DD/MM/YYYY HH:mm")}</div>
      </>
    ),
    options: {
      width: 150,
    },
  },
  {
    header: "User",
    render: (e) => (
      <>
        <Link to={`/users/${e.user_id}`}>{e?.user?.firstName + " " + e?.user?.lastName}</Link>
      </>
    ),
    options: {
      width: 200,
    },
  },
  {
    header: "From",
    dataKey: "location",
    options: {
      width: 200,
    },
  },
  {
    header: "To",
    dataKey: "final_destination_address",
    options: {
      width: 200,
    },
  },
  { header: "Distance", render: (e) => `${(e.distance / 1000).toFixed(3)} km` },
  { header: "Ride Time", render: (e) => `${e.minutes} min` },
  {
    header: "Total Price",
    render: (e) => (
      <span>
        <div>
          {(
            e.platform_fee +
            e.platform_fee_with_vat +
            e.price +
            e.price_with_vat +
            Number.parseFloat(e.priority_fee) +
            Number.parseFloat(e.priority_fee_with_vat) +
            e.tip_captured
          )?.toFixed(2)}{" "}
          RSD
        </div>
        {!!e.priority && (
          <div className={styles.priority}>• Priority {(e.priority_fee + e.priority_fee_with_vat)?.toFixed(2)} RSD</div>
        )}
      </span>
    ),
    options: {
      width: 150,
    },
  },
  { header: "Platform Fee", render: (e) => (e.platform_fee + e.platform_fee_with_vat)?.toFixed(2) + " RSD" },
  { header: "Tip", dataKey: "tip_sent", render: (e) => e.tip_sent?.toFixed(2) + " RSD" },
  {
    header: "Fiscal invoice",
    render: (e) =>
      e?.invoice?.length > 0 && (
        <Button onClick={() => downloadInvoices(e?.invoice)} className={styles.downloadInvoiceButton} variant="white">
          <img height={16} src={downloadIcon} alt="download icon" />
          Download
        </Button>
      ),
    options: {
      width: 160,
      fixed: "right",
    },
  },
];

function RidesTable({ data, loading, onPaginationChange, pagination, hidePagination }) {
  const activePage = data?.current_page;
  const totalElements = data?.total;
  const pageSize = pagination?.size || data?.per_page;

  return (
    <TableWrapper
      loading={loading}
      totalElements={totalElements}
      pageSize={pageSize}
      activePage={activePage}
      columns={columns}
      renderEmpty={(e) => <CustomEmptyState />}
      onChange={onPaginationChange}
      data={data?.data || []}
      hidePagination={hidePagination}
    />
  );
}

export default RidesTable;

const CustomEmptyState = () => {
  return <div className={styles.noDataWrap}>You don't have services</div>;
};
