import { Message, toaster } from "rsuite";
import NetworkService from "../NetworkService";

export const downloadFile = (data, name) => {
  const blobUrl = window.URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = `${name}.pdf`;
  a.click();
  URL.revokeObjectURL(blobUrl);
};

export const downloadInvoices = (invoices) => {
  invoices.forEach((invoiceItem) => {
    const base64ImageData = `data:image/jpg;base64,${invoiceItem?.invoice}`;
    downloadBase64Image(base64ImageData, invoiceItem.id);
  });
};

function downloadBase64Image(base64Data, fileName) {
  const blob = dataURItoBlob(base64Data);

  const blobUrl = URL.createObjectURL(blob);

  const downloadLink = document.createElement("a");
  downloadLink.href = blobUrl;
  downloadLink.download = fileName;
  downloadLink.click();
  URL.revokeObjectURL(blobUrl);
}

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const refetchCompanyData = (setCompanyData) => {
  NetworkService.getTodaysData().then((res) => {
    setCompanyData(res);
  });
};

export const getUserById = (userId, setUserData) => {
  NetworkService.getUserById(userId).then((res) => {
    setUserData(res[0]);
  });
};

export const toasterFunction = (message, options = {}) => {
  console.log("run");
  toaster.push(message, { placement: options?.placement || "topCenter", duration: options?.duration || 5000 });
};

export const Classes = {
  1: "Mini",
  2: "Eco",
  4: "Biz",
};

export const CarClasses = {
  1: { label: "Mini", description: "Pet friendly, Extra Luggage" },
  2: { label: "Eco", description: "Child Seat, Extra Luggage" },
  4: { label: "Biz", description: "Extra Luggage" },
};

export const Days = {
  monday: {
    label: "Monday",
  },
  tuesday: {
    label: "Tuesday",
  },
  wednesday: {
    label: "Wednesday",
  },
  thursday: {
    label: "Thursday",
  },
  friday: {
    label: "Friday",
  },
  saturday: {
    label: "Saturday",
  },
  sunday: {
    label: "Sunday",
  },
};

export function compareTimes(a, b) {
  const aSplit = a.split(":");
  const bSplit = b.split(":");

  const aHour = Number(aSplit[0]);
  const bHour = Number(bSplit[0]);

  const aMinute = Number(aSplit[1]);
  const bMinute = Number(bSplit[1]);

  if (aHour > bHour) {
    return -1;
  }
  if (bHour > aHour) {
    return 1;
  }
  if (aMinute > bMinute) {
    return -1;
  }
  if (bMinute > aMinute) {
    return 1;
  }
  return 0;
}

export function validWorkingHoursForADay(data) {
  let prev = undefined;

  for (let item of data) {
    if (compareTimes(item[0], item[1]) === -1) {
      toasterFunction(
        <Message closable type="error">
          Error in timetables values!
        </Message>
      );
      return false;
    }
    if (prev && compareTimes(prev[1], item[0]) === -1) {
      toasterFunction(
        <Message closable type="error">
          Error in timetables values!
        </Message>
      );
      return false;
    }
    prev = item;
  }

  return true;
}
